import { apiClient } from "@/services";
import { LoginInformation } from "@/utils/types/user";
import { setCookie, getCookie } from "typescript-cookie";

export const UserService = {
  async userLogin(payload: LoginInformation) {
    const res = await apiClient.post("/user/login", payload);
    return res;
  },
  userLogout() {
    return apiClient.get("/user/logout");
  },
  checkToken() {
    if (getCookie("checkToken")) {
      return Promise.resolve();
    }

    const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);
    setCookie("checkToken", "token", { expires: inFifteenMinutes });

    return apiClient.get("/user/check-token");
  },
};
