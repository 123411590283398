import axios from "axios";
import { getCookie, removeCookie } from "typescript-cookie";
import { UserService } from "@/services/UserService";

export const BASE_API_URL =
  process.env.NODE_ENV === "development"
    ? "https://dev.jfnet.de:8000/api/"
    : "/api/";
export const apiClient = axios.create({
  baseURL: BASE_API_URL,
});

apiClient.interceptors.request.use((config) => {
  const bearer = getCookie("user_grant_access");
  if (bearer) {
    if (config.url !== "/user/check-token") {
      UserService.checkToken()
        .then(() => {
          if (config.headers) {
            config.headers.Authorization = bearer;
          }
        })
        .catch(() => {
          removeCookie("user_grant_access");
          window.location.href = "/login";
        })
        .finally(() => {
          return;
        });
    }

    if (config.headers) {
      config.headers.Authorization = bearer;
    }
  }

  return config;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expiredToken =
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.detail === "Expired token";
    if (expiredToken) {
      removeCookie("user_grant_access");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export const apiFormData = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformRequest: [
    (data, headers) => {
      // Do whatever you want to transform the data

      const formData = new FormData();

      for (const key in data) {
        if (data[key] || data[key] === 0 || data[key] === false) {
          const value = data[key];
          if (Array.isArray(value)) {
            value.forEach((v) => {
              formData.append(`${key}[]`, v);
            });
          } else {
            formData.append(key, value);
          }
        }
      }

      return formData;
    },
  ],
});

apiFormData.interceptors.request.use((config) => {
  const bearer = getCookie("user_grant_access");
  if (bearer) {
    if (config.url !== "/user/check-token") {
      UserService.checkToken()
        .then(() => {
          if (config.headers) {
            config.headers.Authorization = bearer;
          }
        })
        .catch(() => {
          removeCookie("user_grant_access");
          window.location.href = "/login";
        })
        .finally(() => {
          return;
        });
    }

    if (config.headers) {
      config.headers.Authorization = bearer;
    }
  }

  return config;
});

apiFormData.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expiredToken =
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.detail === "Expired token";
    if (expiredToken) {
      removeCookie("user_grant_access");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export const apiBlob = axios.create({
  baseURL: BASE_API_URL,
  responseType: "blob",
});

apiBlob.interceptors.request.use((config) => {
  const bearer = getCookie("user_grant_access");
  if (bearer) {
    if (config.url !== "/user/check-token") {
      UserService.checkToken()
        .then(() => {
          if (config.headers) {
            config.headers.Authorization = bearer;
          }
        })
        .catch(() => {
          removeCookie("user_grant_access");
          window.location.href = "/login";
        })
        .finally(() => {
          return;
        });
    }

    if (config.headers) {
      config.headers.Authorization = bearer;
    }
  }

  return config;
});

apiBlob.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expiredToken =
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.detail === "Expired token";
    if (expiredToken) {
      removeCookie("user_grant_access");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);

export const apiExcel = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type":
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  responseType: "arraybuffer",
});

apiExcel.interceptors.request.use((config) => {
  const bearer = getCookie("user_grant_access");
  if (bearer) {
    if (config.url !== "/user/check-token") {
      UserService.checkToken()
        .then(() => {
          if (config.headers) {
            config.headers.Authorization = bearer;
          }
        })
        .catch(() => {
          removeCookie("user_grant_access");
          window.location.href = "/login";
        })
        .finally(() => {
          return;
        });
    }

    if (config.headers) {
      config.headers.Authorization = bearer;
    }
  }

  return config;
});

apiExcel.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const expiredToken =
      error.response.status === 500 &&
      error.response.data &&
      error.response.data.detail === "Expired token";
    if (expiredToken) {
      removeCookie("user_grant_access");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);
